import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inputField", "buttonBackground", "buttonInside"];

  toggle() {
    $(this.buttonBackgroundTarget)
      .toggleClass("bg-gray-200")
      .toggleClass("bg-green-500");
    $(this.buttonInsideTarget).toggleClass("translate-x-0 translate-x-5");
    this.inputFieldTarget.value =
      this.inputFieldTarget.value == "true" ? "false" : "true";
  }
}
