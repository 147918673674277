// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import { Dropdown } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);

// Debug mode for stimulus in development
// https://github.com/hotwired/stimulus/pull/354
// application.debug = process.env.NODE_ENV === "development";
