// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Rails = require("@rails/ujs");
require("turbolinks").start();
require("@rails/activestorage").start();
require("chartkick/chart.js");

require("jquery");
import "../stylesheets/application.scss";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("turbolinks:load", function () {
  $("#mobile_menu_button").click(function () {
    $("#mobile_menu").toggleClass(["block", "hidden"]);
  });
});

import "controllers";
Rails.start();

// Trix editor
import "trix";
window.addEventListener("trix-file-accept", function (event) {
  event.preventDefault();
  alert("File attachments are not supported!");
});
