import { Controller } from "stimulus";
import { globalModal } from "../utils/contants";
export default class extends Controller {
  addNewEducation() {
    $.ajax({
      type: "GET",
      url: `/profile_educations/new`,
      success: function (response) {
        globalModal().setModalHtml(response);
        globalModal().openModal();
      }.bind(this),
      error: function (response) {},
    });
  }

  appendProfileEducation(event) {
    location.reload();
  }

  profileEducationInvalidError(event) {
    const [data, status, xhr] = event.detail;
    globalModal().setModalHtml(xhr.response);
  }

  editEducation(event) {
    $.ajax({
      type: "GET",
      url: `/profile_education/edit/${event.params.educationId}`,
      success: function (response) {
        globalModal().setModalHtml(response);
        globalModal().openModal();
      }.bind(this),
      error: function (response) {},
    });
  }

  deleteEducation(event) {
    $.ajax({
      type: "POST",
      url: `/profile_education/delete/${event.params.educationId}`,
      success: function (response) {
        location.reload();
      },
      error: function (response) {},
    });
  }

  closeDialog() {
    globalModal().closeModal();
  }
}
