import { Controller } from "stimulus";
import { globalModal } from "../utils/contants";
export default class extends Controller {
  static targets = ["linkList", "newLinkButton"];

  addNewLink() {
    $.ajax({
      type: "GET",
      url: `/profile_links/new`,
      success: function (response) {
        globalModal().setModalHtml(response);
        globalModal().openModal();
      }.bind(this),
      error: function (response) {},
    });
  }

  appendProfileLink(event) {
    location.reload();
  }

  profileLinkInvalidError(event) {
    const [data, status, xhr] = event.detail;
    globalModal().setModalHtml(xhr.response);
  }

  editLink(event) {
    $.ajax({
      type: "GET",
      url: `/profile_link/edit/${event.params.linkId}`,
      success: function (response) {
        globalModal().setModalHtml(response);
        globalModal().openModal();
      }.bind(this),
      error: function (response) {},
    });
  }

  deleteLink(event) {
    $.ajax({
      type: "POST",
      url: `/profile_link/delete/${event.params.linkId}`,
      success: function (response) {
        location.reload();
      },
      error: function (response) {},
    });
  }

  closeDialog() {
    globalModal().closeModal();
  }
}
