import { Controller } from "stimulus";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { globalModal } from "../utils/contants";

export default class extends Controller {
  static values = { jobId: Number, bookmarked: Boolean, userLoggedIn: Boolean };
  static targets = ["bookmarkText"];
  connect() {
    this.notyf = new Notyf({
      duration: 4000,
      position: { x: "center", y: "top" },
      dismissible: true,
    });
  }

  openSignInModal() {
    $("#login_modal_root")[0]["login-modal"].openLoginModal();
  }

  quickPreview(event) {
    $.ajax({
      type: "GET",
      url: `/jobs/${this.jobIdValue}/quick-preview`,
      success: function (response) {
        globalModal().setModalHtml(response, "sm:max-w-4xl");
        globalModal().openModal();
      }.bind(this),
      error: function (response) {},
    });
  }

  addToKanban(event) {
    if (this.userLoggedInValue) {
      $.ajax({
        type: "POST",
        url: `/kanban/jobs/${this.jobIdValue}`,
        statusCode: {
          409: function (responseObject, textStatus, jqXHR) {
            this.notyf.success("Already added to Kanban");
          }.bind(this),
        },
        success: function (response) {
          this.notyf.success("Added to Kanban");
        }.bind(this),
        error: function (response) {},
      });
    } else {
      this.openSignInModal();
    }
  }

  bookmarkJob(event) {
    if (this.userLoggedInValue) {
      if (this.bookmarkedValue) {
        $.ajax({
          type: "POST",
          url: `/bookmarks/${this.jobIdValue}/remove`,
          success: function (response) {
            this.bookmarkedValue = false;
            this.bookmarkTextTarget.textContent = "Bookmark";
            this.notyf.success("Removed bookmark");
          }.bind(this),
          error: function (response) {},
        });
      } else {
        $.ajax({
          type: "POST",
          url: `/bookmarks/${this.jobIdValue}`,
          success: function (response) {
            this.bookmarkedValue = true;
            this.bookmarkTextTarget.textContent = "Un-bookmark";
            this.notyf.success("Bookmarked!");
          }.bind(this),
          error: function (response) {},
        });
      }
    } else {
      this.openSignInModal();
    }
  }
}
