import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["completedList", "incompleteList"];

  deleteTodo(event) {
    event.preventDefault();
    var todoId = event.currentTarget.dataset.todoid;
    $.ajax({
      type: "GET",
      data: {
        completed: event.currentTarget.checked,
      },
      url: `/job_item_todos/delete/${todoId}`,
      success: function (response) {
        document.getElementById(`job_item_todo_${todoId}`).remove();
      }.bind(this),
      error: function (response) {},
    });
  }

  updateTodo(event) {
    var todoId = event.currentTarget.dataset.todoId;
    var checked = event.currentTarget.checked;
    $.ajax({
      type: "POST",
      data: {
        completed: checked,
      },
      url: `/job_item_todos/update/${todoId}`,
      success: function (response) {
        var liNode = document.getElementById(`job_item_todo_${todoId}`);
        if (checked) {
          this.completedListTarget.appendChild(liNode);
        } else {
          this.incompleteListTarget.appendChild(liNode);
        }
      }.bind(this),
      error: function (response) {},
    });
  }
}
