import { Controller } from "stimulus";
import { globalModal } from "../utils/contants";

export default class extends Controller {
  openSharewidget(event) {
    var template = document.querySelector("#share_modal_content");
    globalModal().setModalHtml(template.innerHTML, "sm:max-w-md");
    globalModal().openModal();
  }
}
