import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["eventsList"];

  prependEvent(event) {
    const [data, status, xhr] = event.detail;
    $(this.eventsListTarget).prepend(xhr.response);
    event.currentTarget.reset();
  }
}
