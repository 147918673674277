import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mobileMenu"];
  connect() {
    this.menuOpen = false;
  }
  toggleMobileMenu() {
    if (this.menuOpen) {
      this.mobileMenuTarget.classList.add("opacity-0", "scale-95");
      this.mobileMenuTarget.classList.remove("opacity-100", "scale-100");
      this.menuOpen = false;
    } else {
      this.mobileMenuTarget.classList.remove("opacity-0", "scale-95");
      this.mobileMenuTarget.classList.add("opacity-100", "scale-100");
      this.menuOpen = true;
    }
  }
}
