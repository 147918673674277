import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contactsList"];
  appendContact(event) {
    const [data, status, xhr] = event.detail;
    $(this.contactsListTarget).append(xhr.response);
    event.currentTarget.reset();
  }
}
