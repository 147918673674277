import { Controller } from "stimulus";
import Sortable from "sortablejs";
import { MODAL_SPINNER_HTML } from "../utils/contants";
import { SPINNER_HTML } from "../utils/contants";
import { useClickOutside } from "stimulus-use";
import consumer from "../channels/consumer";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = [
    "modalPanel",
    "modalBackground",
    "inset",
    "todosTracker",
    "contactsTracker",
    "reportsTracker",
    "jobTracker",
    "section",
    "sidebar",
    "jobItemSearchField",
  ];
  static values = {
    activeSection: String,
    modalActive: Boolean,
    sidebarOpen: Boolean,
  };

  connect() {
    // Magic
    this.element[this.identifier] = this;

    // Automatic debounce
    this.triggerJobItemSearch = debounce(this.triggerJobItemSearch, 500).bind(
      this
    );

    this.subscription = consumer.subscriptions.create(
      {
        channel: "KanbanChannel",
      },
      {
        connected: () => {
          console.log("connected");
        },
        received: this._received.bind(this),
      }
    );

    let all_statuses = [
      "to_apply",
      "applied",
      "in_progress",
      "action_required",
      "waiting_for_response",
      "offer",
      "rejected",
    ];

    all_statuses.forEach(function (status) {
      var el = document.getElementById(status);
      Sortable.create(el, {
        group: "shared", // set both lists to same group
        animation: 150,
        onEnd: function (evt) {
          var data = {
            from_id: evt.from.id,
            to_id: evt.to.id,
            old_index: evt.oldDraggableIndex + 1,
            new_index: evt.newDraggableIndex + 1,
          };
          $.ajax({
            type: "POST",
            url: "/kanban/update",
            data: data,
            success: function (repsonse) {},
            error: function (repsonse) {},
          });
        },
      });
    });
    useClickOutside(this, {
      element: this.modalPanelTarget,
      onlyVisible: false,
    });
  }

  openContactInfo(event) {
    this.openDialogAddClasses();
    this.modalPanelTarget.innerHTML = MODAL_SPINNER_HTML;
    var contactId = event.currentTarget.dataset.contactid;
    $.ajax({
      type: "GET",
      url: `/job_item_contacts/edit/${contactId}`,
      success: function (repsonse) {
        this.modalPanelTarget.innerHTML = repsonse;
        this.modalActiveValue = true;
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  _received(data) {
    switch (data.action) {
      case "refresh_job_section": {
        this.appendJobItem();
      }
    }
  }

  searchJobItems(event) {
    let searchQuery = this.jobItemSearchFieldTarget.value;
    this.sectionTarget.innerHTML = SPINNER_HTML;
    $.ajax({
      type: "GET",
      url: `kanban/job_items/search?query=${searchQuery}`,
      success: function (response) {
        this.sectionTarget.innerHTML = response;
      }.bind(this),
    });
  }

  triggerJobItemSearch(event) {
    this.searchJobItems();
  }

  changeSection(event) {
    var section = event.currentTarget.dataset.section;
    var previousSection = this.activeSectionValue;
    if (section !== previousSection) {
      this.sectionTarget.innerHTML = SPINNER_HTML;
      $(event.currentTarget).addClass("bg-gray-900 text-white");
      $(event.currentTarget).removeClass(
        "text-gray-300 hover:bg-gray-700 hover:text-white"
      );
      $(eval(`this.${previousSection}TrackerTarget`)).removeClass(
        "bg-gray-900 text-white"
      );
      $(eval(`this.${previousSection}TrackerTarget`)).addClass(
        "text-gray-300 hover:bg-gray-700 hover:text-white"
      );
      $.ajax({
        type: "GET",
        url: `/kanban/section/${section}`,
        success: function (response) {
          this.sectionTarget.innerHTML = response;
          this.activeSectionValue = section;
          if (this.sidebarOpenValue) {
            this.closeSidebar();
          }
        }.bind(this),
        error: function (response) {},
      });
    }
  }

  openDialog(event) {
    this.openDialogAddClasses();
    this.modalPanelTarget.innerHTML = MODAL_SPINNER_HTML;
    let id = event.currentTarget.dataset.jobitem;
    $.ajax({
      type: "GET",
      url: `/kanban/job_item/${id}`,
      success: function (repsonse) {
        this.modalPanelTarget.innerHTML = repsonse;
        this.modalActiveValue = true;
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  openEditJob(jobItemId) {
    this.closeDialog();
    this.openDialogAddClasses();
    this.modalPanelTarget.innerHTML = MODAL_SPINNER_HTML;
    $.ajax({
      type: "GET",
      url: `/job_items/edit/${jobItemId}`,
      success: function (repsonse) {
        this.modalPanelTarget.innerHTML = repsonse;
        this.modalActiveValue = true;
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  openAddJob(event) {
    this.openDialogAddClasses();
    this.modalPanelTarget.innerHTML = MODAL_SPINNER_HTML;
    $.ajax({
      type: "GET",
      url: `/job_items/new_form`,
      success: function (repsonse) {
        this.modalPanelTarget.innerHTML = repsonse;
        this.modalActiveValue = true;
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  closeAddJob(event) {
    this.closeDialog();
  }

  appendJobItem(event) {
    this.closeDialog();
    this.reloadSection("job");
  }

  appendJobItemContact(event) {
    this.closeDialog();
    this.reloadSection("contacts");
  }

  showSidebar(event) {
    this.sidebarTarget.classList.add("w-56", "absolute", "z-40", "h-screen");
    this.sidebarTarget.classList.remove("w-0");
    this.sidebarOpenValue = true;
  }

  closeSidebar(event) {
    this.sidebarTarget.classList.remove("w-56", "absolute", "z-40", "h-screen");
    this.sidebarTarget.classList.add("w-0");
    this.sidebarOpenValue = false;
  }

  jobItemValidationError(event) {
    const [data, status, xhr] = event.detail;
    this.modalPanelTarget.innerHTML = xhr.response;
  }

  jobItemContactValidationError(event) {
    const [data, status, xhr] = event.detail;
    this.modalPanelTarget.innerHTML = xhr.response;
  }

  changeJobItemStatus(event) {
    var jobItemId = event.currentTarget.dataset.jobitem;
    var status = event.currentTarget.dataset.status;
    $.ajax({
      type: "POST",
      url: `/job_items/update_status/${jobItemId}/${status}`,
      success: function (repsonse) {
        this.closeDialog();
        this.reloadSection("job");
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  archiveItem(event) {
    let id = event.currentTarget.dataset.jobitem;
    $.ajax({
      type: "POST",
      url: `/archive/job_item/${id}`,
      success: function (repsonse) {
        this.closeDialog();
        $(`#job_item_${id}`).remove();
      }.bind(this),
      error: function (repsonse) {},
    });
  }

  appendJobItemNote(event) {
    const [data, status, xhr] = event.detail;
    const jobItemId = event.currentTarget.dataset.jobitem;
    $(`#${jobItemId}_notes`).append(xhr.response);
    event.currentTarget.reset();
  }

  closeDialog(event) {
    this.modalActiveValue = false;
    this.closeDialogRemoveClasses();
    this.modalPanelTarget.innerHTML = "";
  }

  closeFromOutside(event) {
    if (!this.modalActiveValue) {
      event.stopPropagation;
    } else {
      event.preventDefault();
      this.closeDialog();
    }
  }

  deleteContact(event) {
    var contactId = event.currentTarget.dataset.contactid;
    $.ajax({
      type: "GET",
      url: `/job_item_contacts/delete/${contactId}`,
      success: function (response) {
        $(`#job_item_contact_${contactId}`).remove();
      }.bind(this),
      error: function (response) {},
    });
  }

  reloadSection(section) {
    this.sectionTarget.innerHTML = SPINNER_HTML;
    $.ajax({
      type: "GET",
      url: `/kanban/section/${section}`,
      success: function (response) {
        this.sectionTarget.innerHTML = response;
      }.bind(this),
      error: function (response) {},
    });
  }

  openDialogAddClasses() {
    $(this.insetTarget).addClass("inset-0");
    this.modalBackgroundTarget.classList.add("ease-out", "duration-300");
    this.modalBackgroundTarget.classList.add("opacity-100", "inset-0");
    this.modalPanelTarget.classList.remove(
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
    this.modalPanelTarget.classList.add("ease-out", "duration-300");
    this.modalPanelTarget.classList.add(
      "opacity-100",
      "translate-y-0",
      "sm:scale-100"
    );
  }

  closeDialogRemoveClasses() {
    this.modalPanelTarget.classList.remove(
      "opacity-100",
      "translate-y-0",
      "sm:scale-100"
    );
    this.modalPanelTarget.classList.add("ease-in", "duration-200");
    this.modalPanelTarget.classList.add(
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
    this.insetTarget.classList.remove("inset-0");
    this.modalBackgroundTarget.classList.add("ease-in", "duration-200");
    this.modalBackgroundTarget.classList.remove("opacity-100", "inset-0");
    this.modalBackgroundTarget.classList.add("opacity-0");
  }
}
