import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contact"];
  static values = { contactId: Number };
  deleteContact(event) {
    $.ajax({
      type: "GET",
      url: `/job_item_contacts/delete/${this.contactIdValue}`,
      success: function (response) {
        this.contactTarget.remove();
      }.bind(this),
      error: function (response) {},
    });
  }
}
