import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["note", "noteContent", "noteUpdateContent", "form"];
  static values = { noteId: Number, noteContent: String };

  deleteJobItemNote(event) {
    $.ajax({
      type: "GET",
      url: `/job_item_notes/delete/${this.noteIdValue}`,
      success: function (response) {
        this.noteTarget.remove();
      }.bind(this),
      error: function (response) {},
    });
  }

  editJobItemNote(event) {
    $.ajax({
      type: "GET",
      url: `/job_item_notes/form/${this.noteIdValue}`,
      success: function (response) {
        this.noteTarget.innerHTML = response;
      }.bind(this),
      error: function (response) {},
    });
  }

  appendUpdatedNote(event) {
    const [data, status, xhr] = event.detail;
    this.noteTarget.innerHTML = xhr.response;
  }

  updateJobNote(event) {
    Rails.fire(this.formTarget, "submit");
  }

  cancelEdit(event) {
    $.ajax({
      type: "GET",
      url: `/job_item_notes/${this.noteIdValue}`,
      success: function (response) {
        this.noteTarget.innerHTML = response;
      }.bind(this),
      error: function (response) {},
    });
  }
}
