import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["incompleteTodos", "completedTodos"];

  appendTodo(event) {
    const [data, status, xhr] = event.detail;
    $(this.incompleteTodosTarget).append(xhr.response);
    event.currentTarget.reset();
  }
}
