import { Controller } from "stimulus";
import { globalModal } from "../utils/contants";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  openLoginModal() {
    var template = document.querySelector("#login_modal_root");
    globalModal().setModalHtml(template.innerHTML, "sm:max-w-4xl");
    globalModal().openModal();
  }
}
