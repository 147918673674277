import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "backgroundColorInput",
    "formBody",
    "avatarInput",
    "avatarPreview",
  ];

  avatarFileInputChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      $(this.avatarPreviewTarget).attr("src", e.target.result);
    };
    reader.readAsDataURL(file);
  }

  updateBackgroundColor(event) {
    const newBackgroundColor = event.params.bgColor;
    const currentBackgroundColor = this.backgroundColorInputTarget.value;
    this.backgroundColorInputTarget.value = newBackgroundColor;
    $(
      '[data-profile-form-bg-color-param="' + currentBackgroundColor + '"]'
    ).removeClass("border-2 border-gray-800");
    $(
      '[data-profile-form-bg-color-param="' + newBackgroundColor + '"]'
    ).addClass("border-2 border-gray-800");
    $(this.formBodyTarget).css("background", newBackgroundColor);
  }
}
