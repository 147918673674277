import { Controller } from "stimulus";
import { SPINNER_HTML } from "../utils/contants";
export default class extends Controller {
  static targets = [
    "section",
    "notesSection",
    "infoSection",
    "contactsSection",
    "timelineSection",
    "todosSection",
  ];
  static values = { jobItemId: Number, activeSection: String };

  reshuffleTodoSection(event) {
    this.sectionTarget.innerHTML = SPINNER_HTML;
    $.ajax({
      type: "GET",
      url: `/job_items/${this.jobItemIdValue}/todos`,
      success: function (response) {
        this.sectionTarget.innerHTML = response;
        this.activeSectionValue = "todos";
      }.bind(this),
      error: function (response) {},
    });
  }

  editItem(event) {
    $("#kanban_root")[0].kanban.openEditJob(this.jobItemIdValue);
  }

  changeSection(event) {
    this.sectionTarget.innerHTML = SPINNER_HTML;
    var section = event.currentTarget.dataset.section;
    var previousSection = this.activeSectionValue;
    if (section !== previousSection) {
      $(event.currentTarget).addClass("border-indigo-500 text-indigo-600");
      $(event.currentTarget).removeClass(
        "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
      );
      $(eval(`this.${previousSection}SectionTarget`)).removeClass(
        "border-indigo-500 text-indigo-600"
      );
      $(eval(`this.${previousSection}SectionTarget`)).addClass(
        "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
      );
      $.ajax({
        type: "GET",
        url: `/job_items/${this.jobItemIdValue}/${section}`,
        success: function (response) {
          this.sectionTarget.innerHTML = response;
          this.activeSectionValue = section;
        }.bind(this),
        error: function (response) {},
      });
    }
  }
}
