import { Controller } from "stimulus";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte

export default class extends Controller {
  static targets = ["emailField", "listField"];

  createSubscription(event) {
    const notyf = new Notyf({
      duration: 4000,
      position: { x: "center", y: "top" },
      dismissible: true,
    });
    event.preventDefault();
    let list = null;
    if (this.hasListFieldTarget) {
      list = this.listFieldTarget.value;
    }
    var email = this.emailFieldTarget.value;
    if (this.validateEmail(email)) {
      $.ajax({
        type: "POST",
        url: `/subscriptions`,
        data: {
          email: email,
          list: list,
        },
        success: function (response) {
          notyf.success(response.message);
        }.bind(this),
        error: function (response) {
          notyf.error("Please eneter valid email");
        }.bind(this),
      });
    } else {
      notyf.error("Please enter a valid email");
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
