import { Controller } from "stimulus";
import "chartkick/chart.js";

export default class extends Controller {
  connect() {
    new Chartkick.PieChart(
      "job-item-status-pie",
      "/reports/data/job-item-status"
    );
    new Chartkick.LineChart(
      "job-item-created-line",
      "/reports/data/job-item-created"
    );
  }
}
