import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";

export default class extends Controller {
  static values = { todoId: Number };
  static targets = ["todo"];
  connect() {
    useDispatch(this);
  }

  deleteJobItemtodo(event) {
    $.ajax({
      type: "GET",
      data: {
        completed: event.currentTarget.checked,
      },
      url: `/job_item_todos/delete/${this.todoIdValue}`,
      success: function (response) {
        this.todoTarget.remove();
      }.bind(this),
      error: function (response) {},
    });
  }

  updateTodo(event) {
    $.ajax({
      type: "POST",
      data: {
        completed: event.currentTarget.checked,
      },
      url: `/job_item_todos/update/${this.todoIdValue}`,
      success: function (response) {
        this.dispatch("reshuffle", {});
      }.bind(this),
      error: function (response) {},
    });
  }
}
